import React from 'react';

import { map, reduce, isEmpty } from 'lodash-es';
import ButtonGroup from 'mineral-ui/ButtonGroup';
import PropTypes from 'prop-types';

import {
  $menu,
  $menuPlaceholder
} from './style';
import FormattingButton from '../FormattingButton';

/**
 * Formatting Menu, with dividers between marks, blocks, insert, and history items.
 */

const DIVIDER = [{ isDivider: true }];

/**
 * Determine if a divider should be added between two items.
 *
 * @param  {Object} prev
 * @param  {Object} next
 * @return {boolean}
 */
function needsDivider (prev, next) {
  return !isEmpty(prev) || !isEmpty(next);
}

/**
 * Determine if an item is a divider.
 * @param {object} item
 * @returns {boolean}
 */
function isDivider (item) {
  return item.isDivider;
}

/**
 * Sometimes the simple code above will produce situations with double dividers,
 * so remove them in a second step. Note that this returns false if there IS a
 * double divider, because it is called in an Array.filter() method.
 * Exported for testing.
 *
 * @param  {Object} item
 * @param  {number} index
 * @param  {Array} list
 * @return {boolean}
 */
export function removeDoubleDividers (item, index, list) {
  const prev = list[index - 1];

  if (isDivider(item) && (!prev || (!!prev && isDivider(prev)))) {
    return false;
  } else {
    return true;
  }
}

export default function FormattingMenu ({ items, statefulView, state, levelerStatus, isProseMirror }) {
  if (!items || !statefulView.current || !state) {
    // Menu will be instantiated before prosemirror finishes its rendering,
    // so render a placeholder with visibility: hidden.
    return <div css={$menuPlaceholder} />;
  }

  const allItems = map(items.marks).concat(
    needsDivider(items.marks, items.blocks) ? DIVIDER : [],
    map(items.blocks),
    map(items.history),
    needsDivider(items.blocks, items.insert) ? DIVIDER : [],
    map(items.insert),
    needsDivider(items.insert, items.powerWord) ? DIVIDER : [],
    map(items.leveler),
    map(items.toggleLeveler),
    needsDivider(items.toggleLeveler, items.powerWord) ? DIVIDER : [],
    map(items.powerWord),

  ).filter(removeDoubleDividers);

  // Add keys to the buttons. This allows them to rerender when the state
  // changes. We need to add the keys here, in case different buttons are hidden
  // or shown (we can't solely rely on their indices from the filtered list).
  const itemsWithKeys = allItems.map((item, index) => ({ ...item, _key: `menuitem-${index}` }));

  // Some menu formats have a select function that tells whether or not they
  // should be displayed (e.g. image-alignment).
  // If that is present and returns false, omit it from the menu.
  const shownItems = itemsWithKeys.filter((item) => !item.select || item.select(state));

  // Some menu formats change their displayed state based on whether the user
  // has selected text with that format, e.g. bold or italic. If that is present
  // and returns true, add that button to the checked items.
  const checkedItems = reduce(shownItems,
    (acc, item, index) => statefulView.current.hasFocus() && item.active && item.active(state)
      ? acc.concat(index)
      : acc, []);

  return (
    <ButtonGroup
      mode='checkbox'
      checked={checkedItems}
      aria-label='Formatting'
      size='medium'
      fullWidth
      css={(cssArgs) => {
        return $menu(cssArgs, isProseMirror);
      }}
    >
      {map(shownItems, (item) => {
        const isDisabled = !!item.enable && !item.enable(state);
        const CustomButton = item.customButton;

        return (
          CustomButton ? (
            <CustomButton
              item={item}
              levelerStatus={levelerStatus}
              statefulView={statefulView}
            />
          ) : (
            <FormattingButton
              item={item}
              key={item._key}
              statefulView={statefulView}
              isDisabled={isDisabled}
            />
          )
        );
      })}
    </ButtonGroup>
  );
}

FormattingMenu.propTypes = {
  items: PropTypes.object,
  statefulView: PropTypes.object,
  state: PropTypes.object
};
