import React from 'react';

import { ToggleButton } from '@newsela/angelou';
import Tooltip from 'mineral-ui/Tooltip';

const ToggleLeveler = ({ item, levelerStatus }) => {
  return (
    <Tooltip content={item.title}>
      <ToggleButton
        label={item.overrides.label}
        defaultPressed={false}
        disabled={levelerStatus.isToggleLevelerDisabled}
        css={{
          marginLeft: '8px',
          marginRight: '12px',
        }}
      />
    </Tooltip>
  );
};

export const menuToggleLeveler = [
  {
    title: 'Compare with generated text',
    overrides: {
      label: 'Show Leveled Text',
    },
    customButton: ToggleLeveler,
  },
];
